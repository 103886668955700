import {Component, OnDestroy, Optional} from '@angular/core';
import {Auth, authState, signOut, User} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {EMPTY, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'indosuara-app-auth',
  template: `
    <article>
      <header>
        Info Pribadi
      </header>
      <div *ngIf="user | async as u">
        <p>{{ u.displayName }}</p>
        <p>{{ u.phoneNumber }}</p>
      </div>
    </article>

    <article>
      <a *ngIf="showLoginButton" routerLink="/login">
        <button>Log in</button>
      </a>
      <a  *ngIf="showLogoutButton" (click)="logout()" >
        <button>Log out</button>
      </a>
      <a (click)="deleteAccount()">
        <button class="red">
          Delete Account
        </button>
      </a>


    </article>
  `,
  styles: [`
    .red {
      background-color: red;
    }
  `],
})
export class ProfileComponent implements OnDestroy {
  private readonly userDisposable: Subscription|undefined;
  public readonly user: Observable<User | null> = EMPTY;

  showLoginButton = false;
  showLogoutButton = false;

  constructor(@Optional() private auth: Auth, private router: Router) {
    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
          map((u) => !!u),
      ).subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigate(['login']);
        }
        this.showLoginButton = !isLoggedIn;
        this.showLogoutButton = isLoggedIn;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  async logout() {
    return await signOut(this.auth);
  }

  async deleteAccount() {
    this.router.navigate(['delete']);
  }
}

import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot} from '@angular/router';


import {Injectable} from '@angular/core';
import {Auth, authState, signInWithCustomToken} from '@angular/fire/auth';
import {map, Observable} from 'rxjs';


// eslint-disable-next-line new-cap
@Injectable({
  providedIn: 'root',
})
/**
 * AuthGuard a class to simplify frontend auth security
 */
export class AuthGuard {
  constructor(
    protected auth: Auth,
    protected router: Router,
  ) {}


  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin();
  }


  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(route);
  }

  canActivateChild(
      childRoute: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(childRoute);
  }


  protected checkLogin(route?: ActivatedRouteSnapshot): Observable<boolean> {
    const customToken = route?.queryParams['customToken'];

    if (customToken) {
      signInWithCustomToken(this.auth, customToken).catch(()=>{
        console.log('failed to verify token');
      });
    }
    return authState(this.auth).pipe( map((u)=> {
      if (u == null) {
        this.router.navigate(['login']);
        return false;
      }
      return true;
    }));
  }


  getResolvedUrl(route?: ActivatedRouteSnapshot): string {
    if (!route) {
      return '';
    }

    return route.pathFromRoot
        .map((r) => r.url.map((segment) => segment.toString()).join('/'))
        .join('/')
        .replace('//', '/');
  }
}

import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';
import {environment} from '../environments/environment';

import {AuthGuard} from './auth/auth-guard.service';
import {DeleteConfirmationComponent} from './delete-confirmation/delete-confirmation.component';
import {DeleteSuccessComponent} from './delete-success/delete-confirmation.component';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';


let devRoutes: Routes = [];

if (!environment.production) {
  devRoutes = [];
}

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],

  },
  {path: 'login', component: LoginComponent},
  {path: 'login/:redirectUrl', component: LoginComponent},
  {
    path: 'delete',
    component: DeleteConfirmationComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'delete-success',
    component: DeleteSuccessComponent,
  },


  // {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot([...devRoutes, ...routes])],
  exports: [RouterModule],
})

/**
 * Main app routing module
 */
export class AppRoutingModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'sacred-lane-331603',
    appId: '1:122892781115:web:22e63fe925e3402de17e1e',
    databaseURL: 'https://sacred-lane-331603-default-rtdb.firebaseio.com',
    storageBucket: 'sacred-lane-331603.appspot.com',
    locationId: 'asia-northeast2',
    apiKey: 'AIzaSyDBMAh_QsXv_x8HTwavfNCBmaexsP6OaIY',
    authDomain: 'sacred-lane-331603.firebaseapp.com',
    messagingSenderId: '122892781115',
    measurementId: 'G-VWPR4DG2EY',
  },
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames
 * such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in
 * production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {Component, OnDestroy, Optional} from '@angular/core';
import {FirebaseError} from '@angular/fire/app';
import {Auth, ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber, Unsubscribe} from '@angular/fire/auth';
import {Router} from '@angular/router';

@Component({
  selector: 'indosuara-app-login',
  template: `
    <article>

      <label for="phoneNumber">
        Phone Number / Nomor Telfon Taiwan
      <input
        type="tel"
        id="phoneNumber"
        [(ngModel)]="phoneNumber"
        name="phoneNumber"
        placeholder="example: 0912345678"
        [disabled]="isWaitingForOtp"
      >
      </label>

      <button
        id="sign-in-button"
        (click)="loginWithPhone()"
        [disabled]="isWaitingForOtp"
      >
        Log in by Phone
      </button>

    <div *ngIf="isWaitingForOtp">
      <p>
        SMS OTP sudah terkirim ke nomor telfon {{phoneNumber}}.<br>
        SMS OTP has been sent to {{phoneNumber}}.<br>
        Harap jangan memberikan SMS OTP ke siapapun!<br>
        Please don't share SMS OTP code to anyone!<br>
      </p>
    <input type="number"
        id="otpCode"
       [(ngModel)]="otpCode"
       name="otpCode"
       placeholder="example: 123456"
       >
       <button
      (click)="verifyPhoneOtpCode()">Verify SMS Code</button>
      <progress indeterminate="true"></progress>

    </div>
    </article>
  `,
  styles: [
  ],
})
export class LoginComponent implements OnDestroy {
  redirect = ['/'];

  phoneNumber?:string;
  otpCode?: string;
  confirm?: ConfirmationResult;
  isWaitingForOtp = false;

  disposeAuthListener?: Unsubscribe;


  constructor(@Optional() private auth: Auth, private router: Router) {
    this.disposeAuthListener = this.auth.onAuthStateChanged(
        async (user) => {
          if (user) {
            await this.router.navigate(this.redirect);
            this.isWaitingForOtp = false;
          }
        },
    );
  }
  ngOnDestroy(): void {
    if (this.disposeAuthListener) {
      this.disposeAuthListener();
    }
  }


  async loginWithPhone() {
    if (!this.phoneNumber) return;
    if (this.phoneNumber.length != 10) {
      alert('Please input 10 digit Taiwan\'s phone number. ' +
        'Mohon masukkan 10 digit nomor Taiwan.');
      return;
    }
    try {
      const verifier = new RecaptchaVerifier(
          this.auth,
          'sign-in-button', {
            'size': 'invisible',
          });

      const taiwanPhone = '+886' + this.phoneNumber.substring(1);

      this.confirm = await signInWithPhoneNumber(
          this.auth, taiwanPhone, verifier);

      if (this.confirm) {
        this.isWaitingForOtp = true;
      }
    } catch (e) {
      const err = e as FirebaseError;
      alert(err.code);
    }
  }

  async verifyPhoneOtpCode() {
    if ( this.otpCode) {
      try {
        await this.confirm?.confirm(this.otpCode);
      } catch (e) {
        const err = e as FirebaseError;
        alert(err.code);
      }
    }
  }
}

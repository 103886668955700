import {Component} from '@angular/core';

@Component({
  selector: 'indosuara-delete-confirmation',
  template: `
  <article>
    <header>Akun anda sudah berhasil di hapus.</header>
    <p>
      Your account succesfully deleted.
    </p>
  </article>
  `,
  styles: [

  ],
})
export class DeleteSuccessComponent {

}

import {Component, Optional} from '@angular/core';
import {Auth, authState, deleteUser, User} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';

@Component({
  selector: 'indosuara-delete-confirmation',
  template: `
  <article>
    <header>Are you sure to delete account?</header>
    <p>
      This action will delete current account
      and we will not able to recover anything.
    </p>
    <p>
    <label for="switch">
    <input class="warnchecked"
    type="checkbox"
    id="switch"
    name="switch"
    role="switch"
    [(ngModel)]="userConfirmDelete"
    >
      Yes, I want to delete this account.
      Ya, saya mau hapus akun ini.
    </label>
    </p>

    <button
      class="warn outline"
      (click)="deleteAccount()"
      [disabled]="userConfirmDelete === false">
      Delete Account / Hapus Akun
    </button>
    <button routerLink="/">Cancel / Batal</button>

  </article>
  `,
  styles: [
    `
    .warn{
      border-color: #d10026;
      color: #d10026;
    }
    .warn:hover{
      background-color: #ffe8e8;
    }
    .warnchecked:checked{
      background-color: #d10026;
      border-color: #d10026;
    }

    `,
  ],
})
export class DeleteConfirmationComponent {
  public readonly user: Observable<User | null> = EMPTY;

  userConfirmDelete = false;
  constructor(@Optional() private auth: Auth, private router: Router) {
    if (auth) {
      this.user = authState(this.auth);
    }
  }

  async deleteAccount() {
    if (this.userConfirmDelete) {
      const u = this.auth.currentUser;
      if (u) {
        await deleteUser(u);
      }
      await this.router.navigate(['delete-success']);
    }
  }
}
